import * as React from 'react'
import Footer from "./Footer";
import NavBar from "./NavBar";

//Components

//Global stylesheet
import "./styles/global.css"


//Standard styles


const Layout = ({pageTitle, children}) => {
    return(
        <div>
            <NavBar>

            </NavBar>

            {children}

            <Footer>

            </Footer>
        </div>
    )
}

export default Layout
