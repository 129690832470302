import * as React from 'react'
import {Link} from 'gatsby'

//Components

//Global stylesheet
import "./styles/global.css"
import "./styles/navbar.css"

//Standard styles


const NavBar = () => {
    return(
        <nav className="navbarWrapper">
            <ul className="navbarItems">
                <li>
                    <Link className="navbarItem" to="/">home</Link>
                </li>
                <li>
                    <Link className="navbarItem" to="/projects">projects</Link>
                </li>
                <li>
                    <Link className="navbarItem" to="/contact">contact</Link>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar
