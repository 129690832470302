import * as React from 'react'

//Components

//Global stylesheet
import "./styles/global.css"

//Standard styles


const Footer = ({children}) => {
    return(
        <footer className="footerWrapper">
            {children}
            <p className="footerText">
                ayayaqi.moe  |  there'll eventually be stuff here, i promise.
            </p>
        </footer>
    )
}

export default Footer
